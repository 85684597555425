import React from "react";

const TraslateHome = ({ cambiarId }) => {
    const id1 = "/paginaspanish/648cd8c03c4e88ca0e9fb5e1"; //Variable en español
    const id2 = "/paginas/6470c8ff0faebb8cc0349736"; //Variable en Ingles

    return (
        <div
            className="w-full h-screen relative flex items-center bg1"
        >
            <div className="relative flex flex-col items-center w-full">
                <img
                    src={"https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6470c8ff0faebb8cc0349736%2FiconAndLogo%2FBrother's%20Garcia%20Framing%2C%20LLC.png?alt=media&token=334b9c71-de6f-4939-8ed4-1f3e35b1f949"}
                    alt="no-logo"
                    className="w-[80%] md:w-[30%] pb-5"
                />
                <h1 className="text-white capitalize pb-5">Sitio / Site</h1>
                <div className="flex">
                    <div className="mr-3">
                        <button
                            onClick={() => cambiarId({id: id1, l: 'es'})}
                            className="px-5 py-3 rounded-2xl bg-blue-600 text-white"
                        >
                            Español
                        </button>
                    </div>
                    <div className="ml-3">
                        <button
                            onClick={() => cambiarId({id: id2, l: 'en'})}
                            className="px-5 py-3 rounded-2xl bg-blue-600 text-white"
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome